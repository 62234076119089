import React, { useContext, useMemo } from 'react';
import { EnvContext } from '../../../../contexts/EnvContext';
import { green, teal } from '@mui/material/colors';
import { Box } from '@mui/material';
import { BRANDING_LINE_HEIGHT } from '../Layout';

function EnvLabel({ env }) {
    return (
        <section
            style={{
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                textTransform: 'uppercase',
                fontWeight: 900,
                padding: 0,
                margin: 0,
                textShadow:
                    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
            }}
        >
            {env} Instance
        </section>
    );
}

function EnvLine({ env }) {
    return (
        <div
            style={{
                width: '100%',
                height: BRANDING_LINE_HEIGHT,
                backgroundColor: env == 'development' ? teal[500] : green[500],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <EnvLabel env={env} />
        </div>
    );
}

export default function BrandingLine() {
    const appEnv = useContext(EnvContext);

    return useMemo(() => {
        if (appEnv.value == 'development' || appEnv.value == 'staging') {
            return <EnvLine env={appEnv.value} />;
        }
        return (
            <Box
                sx={{
                    width: '100%',
                    height: BRANDING_LINE_HEIGHT,
                    backgroundImage: 'url("/assets/header-banner.png")',
                    backgroundRepeat: 'round',
                    filter: (theme) =>
                        theme.palette.mode == 'dark'
                            ? 'saturate(0.6) brightness(1.5)'
                            : undefined,
                }}
            />
        );
    }, [appEnv]);
}
