import { CssBaseline, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import mui5Theme, {
    defaultThemeOptions,
    mui5DarkPalette,
    mui5LightPalette,
} from '../../../lib/mui5Theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { EnvContext } from '../../../contexts/EnvContext';
import { deepmerge } from '@mui/utils';
import useSiteTheme from '../../../hooks/useSiteTheme';
import AppRouter from './AppRouter';
import { SWRConfig } from 'swr';
import useEnvironment from './useEnvironment';
import GlobalSnackbarSupport from '../../commonComponents/GlobalSnackbarSupport';

interface ThemedAppProps {
    children: any;
    env: string;
}
function ThemedApplication(props: ThemedAppProps) {
    const prefersDarkModeSystem = useMediaQuery('(prefers-color-scheme: dark)');
    const [userTheme, setUserTheme] = useSiteTheme();
    const theme = useMemo(() => {
        const prefersDarkMode =
            userTheme == 'system' ? prefersDarkModeSystem : userTheme == 'dark';

        return createTheme(
            deepmerge(defaultThemeOptions, {
                palette: prefersDarkMode ? mui5DarkPalette : mui5LightPalette,
            })
        );
    }, [
        prefersDarkModeSystem,
        userTheme,
        mui5DarkPalette,
        mui5LightPalette,
        mui5Theme,
    ]);

    return useMemo(
        () => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>,
        [theme, props.children]
    );
}

export default function App() {
    const env = useEnvironment();

    return (
        <React.StrictMode>
            <EnvContext.Provider value={env}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <SWRConfig
                        value={{
                            errorRetryCount: 3,
                            revalidateOnFocus: false,
                        }}
                    >
                        <ThemedApplication env={env.value}>
                            <CssBaseline enableColorScheme />
                            <GlobalSnackbarSupport>
                                <AppRouter />
                            </GlobalSnackbarSupport>
                        </ThemedApplication>
                    </SWRConfig>
                </LocalizationProvider>
            </EnvContext.Provider>
        </React.StrictMode>
    );
}
