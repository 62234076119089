import LoginResult from '../models/loginResult';

export async function authorize(data: {
    login: string;
    password: string;
}): Promise<LoginResult> {
    const response = await fetch('/api/v2/users/sign_in', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const defaultMessage = 'Unable to sign up.';

        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || defaultMessage);
    }

    const loginResult: LoginResult = await response.json();

    if (loginResult.auth_token) {
        window.localStorage.setItem('token', loginResult.auth_token);
    }

    return loginResult;
}
