import { Button } from '@mui/material';

export default function LogOutButton() {
    return (
        <Button
            variant="text"
            fullWidth
            href="/sign_out"
            onClick={() => localStorage.removeItem('token')}
        >
            Log out
        </Button>
    );
}
