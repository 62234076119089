import { useMemo, lazy, Suspense } from 'react';
import {
    Route,
    BrowserRouter,
    Routes,
    useLocation,
    Navigate,
} from 'react-router-dom';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useCurrentUserRoles from '../../../hooks/useCurrentUserRoles';
import ConfirmCodePage from '../../authyComponents/ConfirmCodePage';
import EnableTwoFactorPage from '../../authyComponents/EnableTwoFactorPage';
import Fallback from '../../commonComponents/Fallback';
import { NotFoundPage } from '../../notFoundComponents/NotFoundPage';
import SignInPage from '../../signInComponents/SignInPage';
import { AppIndexPage } from './AppIndexPage';
import Layout, { HeaderType } from './Layout';

const BarcodesPage = lazy(
    () => import('../../barcodesComponents/BarcodesPage')
);

function RequireAuth({ children }: { children: JSX.Element }) {
    const currentUser = useCurrentUser();
    const location = useLocation();

    if (currentUser.error) {
        const message: string = currentUser.error.toString();

        if (message.includes('You are not authorized')) {
            return (
                <Navigate to="/sign_in" state={{ from: location }} replace />
            );
        } else if (message.includes('Need to enable two-factor')) {
            return (
                <Navigate
                    to="/enable_authy"
                    state={{ from: location }}
                    replace
                />
            );
        } else if (message.includes('Need to confirm two-factor')) {
            return (
                <Navigate
                    to="/confirm_authy"
                    state={{ from: location }}
                    replace
                />
            );
        }
    }

    return children;
}

export default function AppRouter() {
    const roles = useCurrentUserRoles();

    const mazevoRoutes = useMemo(() => {
        if (roles.includes('school_lunch_staff')) {
            return (
                <Route
                    element={
                        <RequireAuth>
                            <Layout headerType={HeaderType.WithSignOut} />
                        </RequireAuth>
                    }
                >
                    <Route path="barcodes" element={<BarcodesPage />} />
                </Route>
            );
        }

        return null;
    }, [roles]);

    const authorizationRoutes = (
        <>
            <Route path="sign_in" element={<SignInPage />} />
            <Route path="enable_authy" element={<EnableTwoFactorPage />} />
            <Route path="confirm_authy" element={<ConfirmCodePage />} />
        </>
    );

    const routes = (
        <Route>
            <Route index element={<AppIndexPage />} />
            {mazevoRoutes}
            <Route element={<Layout headerType={HeaderType.Blank} />}>
                <Route path="*" element={<NotFoundPage />} />
                {authorizationRoutes}
            </Route>
            <Route path="*" element={<NotFoundPage />} />
        </Route>
    );

    return (
        <BrowserRouter basename="app">
            <Suspense fallback={<Fallback />}>
                <Routes>{routes}</Routes>
            </Suspense>
        </BrowserRouter>
    );
}
