import React from 'react';
import './index.css';
import App from './app/components/appComponents/App/App';
import reportWebVitals from './reportWebVitals';
import GA4React from 'ga-4-react';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

try {
    setTimeout((_) => {
        const ga4react = new GA4React('G-BKYLEEMR0C');
        ga4react.initialize().catch((err) => console.error(err));
    }, 4000);
} catch (err) {
    console.error(err);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
