import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SidebarDrawer from './SidebarDrawer';
import BlankHeader from './Header/BlankHeader';
import useSidebarAccess from '../hooks/useSidebarAccess';
import { EnvContext } from '../../../contexts/EnvContext';
import HeaderWithSignOut from './Header/HeaderWithSignOut/HeaderWithSignOut';
import { AppBarHeightContext } from '../../../contexts/AppBarHeightContext';
import { FooterHeightContext } from '../../../contexts/FooterHeightContext';

export enum HeaderType {
    Parent,
    Admin,
    Blank,
    WithSignOut,
}

interface props {
    headerType?: HeaderType;
    footer?: JSX.Element;
}

export const BRANDING_LINE_HEIGHT = 16;
const APP_BAR_TOOLBAR_HEIGHT = 58;
const APP_BAR_HEIGHT = BRANDING_LINE_HEIGHT + APP_BAR_TOOLBAR_HEIGHT;
export const FOOTER_HEIGHT = 40;

export default function Layout(props: props) {
    const themeSource = useTheme();
    const isDesktop = useMediaQuery(themeSource.breakpoints.up('md'));
    const [showSidebar, setShowSidebar] = useState(false);
    const headerType =
        props.headerType !== undefined ? props.headerType : HeaderType.Blank;
    const sidebarAccess = useSidebarAccess();
    const appEnv = useContext(EnvContext);
    const appBarHeight = useMemo(
        () =>
            appEnv.brand == 'lm'
                ? APP_BAR_TOOLBAR_HEIGHT + BRANDING_LINE_HEIGHT
                : APP_BAR_TOOLBAR_HEIGHT,
        [appEnv.brand, BRANDING_LINE_HEIGHT, APP_BAR_TOOLBAR_HEIGHT]
    );
    const footerHeight = useMemo(
        () => (props.footer ? FOOTER_HEIGHT : 0),
        [props.footer, FOOTER_HEIGHT]
    );

    const Main = useMemo(
        () =>
            styled('main')(({ theme }) => ({
                marginTop: appBarHeight,
                height: `calc(100vh - ${appBarHeight}px)`,
                maxHeight: `calc(100vh - ${appBarHeight}px)`,
                overflow: 'auto',

                [theme.breakpoints.up('md')]: {
                    height: `calc(100vh - ${appBarHeight}px - ${footerHeight}px)`,
                    maxHeight: `calc(100vh - ${appBarHeight}px - ${footerHeight}px)`,
                },
            })),
        [appBarHeight, footerHeight]
    );

    useEffect(() => {
        if (appEnv.brand == 'lm') {
            document.title = 'The LunchMaster';
        } else {
            document.title = 'Mazevo';
        }
    }, [appEnv]);

    const mainContent = useMemo(
        () =>
            isDesktop ? (
                <Outlet />
            ) : (
                <>
                    <Outlet />
                    {props.footer}
                </>
            ),
        [isDesktop, props.footer]
    );
    const endingContent = useMemo(() => {
        if (props.footer) {
            return isDesktop ? props.footer : <></>;
        }

        return undefined;
    }, [isDesktop, props.footer]);

    const getHeader = () => {
        const headerProps = {
            onShowSidebarClicked: () => setShowSidebar(true),
        };

        if (headerType.valueOf() === HeaderType.WithSignOut.valueOf()) {
            return <HeaderWithSignOut {...headerProps} />;
        }

        return <BlankHeader {...headerProps} />;
    };

    return (
        <AppBarHeightContext.Provider value={appBarHeight}>
            <FooterHeightContext.Provider value={footerHeight}>
                {getHeader()}
                {sidebarAccess && (
                    <SidebarDrawer
                        show={showSidebar}
                        onHideClicked={() => setShowSidebar(false)}
                    />
                )}
                <Main>{mainContent}</Main>
                {endingContent}
            </FooterHeightContext.Provider>
        </AppBarHeightContext.Provider>
    );
}
