import { Alert, AlertColor, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SnackOptions from '../../models/snackOptions';

interface props {
    options?: SnackOptions;
}

const DEFAULT_OPTIONS: SnackOptions = { severity: 'success', text: '' };

export default function UniversalSnack(props: props) {
    const options = props.options || DEFAULT_OPTIONS;
    const { text, severity } = options;
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        setShowSnackbar(props.options !== undefined);
    }, [props.options]);

    return (
        <Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)}>
            <Alert severity={severity} variant="filled" elevation={6}>
                <Typography>{text}</Typography>
            </Alert>
        </Snackbar>
    );
}
