import { getAuthenticityToken } from '../lib/utils';
import { AccountDto } from '../models/accountDto';
import UserDto from '../models/userDto';

export async function enable(
    cellphone: string,
    countryCode: string
): Promise<void> {
    const response = await fetch(`/api/v2/authy/phone`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            cellphone: cellphone,
            country_code: countryCode,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to save the phone number in Authy');
    }
}

export async function confirm(
    token: string
): Promise<{ account: AccountDto; user: UserDto }> {
    const response = await fetch(`/api/v2/authy/code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            token,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to confirm the code');
    }

    return await response.json();
}

export interface RequestResult {
    sent: boolean;
    message: string;
}

export async function requestSMS(): Promise<RequestResult> {
    const response = await fetch(`/api/v2/authy/sms`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
    });

    if (!response.ok) {
        throw new Error(
            (await response.json()).message || 'Failed to request an SMS'
        );
    }

    return await response.json();
}
