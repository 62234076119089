import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import SnackOptions from '../../models/snackOptions';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import API from '../../api';

interface props {
    onSnack: (options: SnackOptions) => any;
}

export default function RequestTokenViaSMSButton(props: props) {
    const [loading, setLoading] = useState(false);

    const handleButtonClick = async () => {
        if (loading) return;

        setLoading(true);

        try {
            const result = await API.authy.requestSMS();
            if (!result.sent) throw new Error(result.message);
            props.onSnack({
                severity: 'success',
                text: 'An SMS has been sent to your phone. 📱✌️',
            });
        } catch (error) {
            props.onSnack({
                severity: 'error',
                text: error.toString(),
            });
        }

        setLoading(false);
    };

    return (
        <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            fullWidth
            variant="outlined"
            startIcon={<MailOutlineIcon />}
        >
            Send SMS
        </LoadingButton>
    );
}
