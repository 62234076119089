import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Home } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Chip,
    Divider,
    IconButton,
    ListItemButton,
    Stack,
} from '@mui/material';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import KitchenIcon from '@mui/icons-material/Kitchen';
import { styled } from '@mui/material/styles';
import { Logo } from './Header/Logo';
import BrandingLine from './Header/BrandingLine';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
    useNavigate,
} from 'react-router-dom';
import useBreakpointDown from '../../../hooks/useBreakpointDown';
import { useState } from 'react';
import SidebarLogOut from './Sidebar/SidebarLogOut';
import LogOutAlert from '../../commonComponents/LogOutAlert';
import OnRoles from '../../commonComponents/OnRoles';
import useAppBarHeight from '../../../hooks/useAppBarHeight';

interface props {
    show: boolean;
    onHideClicked: () => any;
}

export const CustomIcon = styled(ListItemIcon)(({ theme }) => ({
    marginLeft: 8,
}));

interface buttonProps {
    to: string;
    dense?: boolean;
    children?: any;
}

export function NavigateListItemButton(props: buttonProps) {
    const renderLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
                function Link(itemProps, ref) {
                    return (
                        <RouterLink
                            to={props.to}
                            ref={ref}
                            {...itemProps}
                            role={undefined}
                        />
                    );
                }
            ),
        [props.to]
    );

    return (
        <ListItemButton component={renderLink} dense={props.dense}>
            {props.children}
        </ListItemButton>
    );
}

function BetaChip({ children }) {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {children}
            <Chip size="small" label="beta" />
        </Stack>
    );
}

export default function SidebarDrawer(props: props) {
    const { show, onHideClicked } = props;
    const [logOutAlertOpen, setLogOutAlertOpen] = useState(false);
    const appBarHeight = useAppBarHeight();
    const dense = true;

    const list = () => (
        <List dense={dense}>
            <OnRoles roles={['superuser', 'lm_staff2']}>
                <NavigateListItemButton to="/admin/dashboard" dense={dense}>
                    <CustomIcon>
                        <Home />
                    </CustomIcon>
                    <BetaChip>
                        <ListItemText primary="Dashboard" />
                    </BetaChip>
                </NavigateListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <ListItemButton
                    component="a"
                    href="/master_menus"
                    dense={dense}
                >
                    <CustomIcon>
                        <DateRangeIcon />
                    </CustomIcon>
                    <ListItemText primary="Menus" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff', 'lm_staff2']}>
                <ListItemButton component="a" href="/menu_items" dense={dense}>
                    <CustomIcon>
                        <MenuIcon />
                    </CustomIcon>
                    <ListItemText primary="Menu Items" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <ListItemButton component="a" href="/ingredients" dense={dense}>
                    <CustomIcon>
                        <MicrowaveIcon />
                    </CustomIcon>
                    <ListItemText primary="Ingredients" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <ListItemButton component="a" href="/schools" dense={dense}>
                    <CustomIcon>
                        <SchoolIcon />
                    </CustomIcon>
                    <ListItemText primary="Institutions" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff', 'lm_staff2']}>
                <ListItemButton
                    component="a"
                    href="/staging_locations"
                    dense={dense}
                >
                    <CustomIcon>
                        <LocalShippingIcon />
                    </CustomIcon>
                    <ListItemText primary="Delivery" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff', 'lm_staff2']}>
                <ListItemButton component="a" href="/kitchens" dense={dense}>
                    <CustomIcon>
                        <KitchenIcon />
                    </CustomIcon>
                    <ListItemText primary="Kitchens" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <ListItemButton component="a" href="/accounts" dense={dense}>
                    <CustomIcon>
                        <PeopleIcon />
                    </CustomIcon>
                    <ListItemText primary="Accounts" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <NavigateListItemButton
                    to="/accounts/self/ordering"
                    dense={dense}
                >
                    <CustomIcon>
                        <PeopleIcon />
                    </CustomIcon>
                    <BetaChip>
                        <ListItemText primary="Accounts" />
                    </BetaChip>
                </NavigateListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff2']}>
                <ListItemButton
                    component="a"
                    href="/admin/accounts"
                    dense={dense}
                >
                    <CustomIcon>
                        <AccountBalanceWalletIcon />
                    </CustomIcon>
                    <ListItemText primary="Accts Receivable" />
                </ListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser']}>
                <NavigateListItemButton to="/admin/promotions" dense={dense}>
                    <CustomIcon>
                        <AdUnitsIcon />
                    </CustomIcon>
                    <BetaChip>
                        <ListItemText primary="Promotions" />
                    </BetaChip>
                </NavigateListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser', 'lm_staff', 'lm_staff2']}>
                <NavigateListItemButton to="/reports/generate" dense={dense}>
                    <CustomIcon>
                        <PointOfSaleIcon />
                    </CustomIcon>
                    <BetaChip>
                        <ListItemText primary="Reports" />
                    </BetaChip>
                </NavigateListItemButton>
            </OnRoles>

            <OnRoles roles={['superuser']}>
                <ListItemButton
                    component="a"
                    href="/admin/system"
                    dense={dense}
                >
                    <CustomIcon>
                        <SettingsIcon />
                    </CustomIcon>
                    <ListItemText primary="Sys Admin" />
                </ListItemButton>
            </OnRoles>

            <SidebarLogOut
                dense={dense}
                onLogOutClicked={() => setLogOutAlertOpen(true)}
            />
        </List>
    );

    return (
        <>
            <Drawer
                anchor="left"
                open={show}
                onClose={onHideClicked}
                PaperProps={{ sx: { borderRadius: 0 } }}
            >
                <Box
                    sx={{ width: 300 }}
                    role="presentation"
                    onClick={onHideClicked}
                    onKeyDown={onHideClicked}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: appBarHeight,
                        }}
                    >
                        <div>
                            <BrandingLine />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                aria-label="open sidebar"
                                onClick={props.onHideClicked}
                                edge="start"
                                sx={{ ml: 2, mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Logo />
                        </div>
                    </div>
                    <Divider />
                    {list()}
                </Box>
            </Drawer>
            <LogOutAlert
                open={logOutAlertOpen}
                onClose={() => setLogOutAlertOpen(false)}
            />
        </>
    );
}
