import { createContext } from 'react';
import SnackOptions from '../models/snackOptions';
import StateContextValue from './StateContext';

const defaultValue: StateContextValue<SnackOptions | undefined> = {
    value: undefined,
    setValue: () => {},
};

export const GlobalSnackbarContext = createContext(defaultValue);
