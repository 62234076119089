import { Grid } from '@mui/material';
import VerifyAuthyTokenForm from './VerifyAuthyTokenForm';

export default function ConfirmCodePage() {
    return (
        <Grid
            container
            sx={{ p: { xs: 2, md: 3 }, width: '100%', height: '100%' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <VerifyAuthyTokenForm />
            </Grid>
        </Grid>
    );
}
