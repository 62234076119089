import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { Logo } from '../appComponents/App/Header/Logo';

interface props {
    borderSize: number;
    logoSize: number;
}

export default function BorderedLogo(props: props) {
    return useMemo(
        () => (
            <Paper
                variant="outlined"
                sx={{
                    p: 2,
                    borderRadius: '100%',
                    width: props.borderSize,
                    height: props.borderSize,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Logo size={props.logoSize} />
            </Paper>
        ),
        [props.borderSize, props.logoSize]
    );
}
