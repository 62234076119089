import { useLocation, useSearchParams } from 'react-router-dom';

export default function useFromUrl() {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsLink = searchParams.get('from');
    const searchParamsDecoded = searchParamsLink
        ? decodeURI(searchParamsLink)
        : null;
    const location = useLocation();
    // @ts-expect-error
    const reactCompatibleLocation = location.state?.from;
    const reactToLegacyLocation = reactCompatibleLocation
        ? '/app' +
          reactCompatibleLocation.pathname +
          reactCompatibleLocation.search
        : null;

    return (
        reactToLegacyLocation ||
        // @ts-expect-error
        location.state?.fromCustom ||
        searchParamsDecoded ||
        null
    );
}
