import React, { useState } from 'react';
import CountryCodeSelect, { CountryType } from './CountryCodeSelect';
import { Button, Grid, ThemeProvider, Typography } from '@mui/material';
import PhoneNumberInput from './PhoneNumberInput';
import { useCountryTypes } from '../../hooks/useCountryTypes';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import { LoadingButton } from '@mui/lab';
import LogOutButton from './LogOutButton';
import API from '../../api';
import { useNavigate } from 'react-router-dom';
import SnackOptions from '../../models/snackOptions';
import UniversalSnack from '../commonComponents/UniversalSnack';

export default function EnableTwoFactorAuthenticationForm() {
    const { defaultCountryType } = useCountryTypes();
    const [loading, setLoading] = useState(false);
    const [countryType, setCountryType] =
        useState<CountryType>(defaultCountryType);
    const [phone, setPhone] = useState<string>('');
    const [snackbar, setSnackbar] = useState<SnackOptions | undefined>();
    const navigate = useNavigate();

    const handleCountySelect = (option: CountryType) => {
        setCountryType(option);
    };

    const submit = async () => {
        setLoading(true);

        try {
            await API.authy.enable(phone, countryType.phone);
            navigate('/confirm_authy', { replace: true });
        } catch (error) {
            setSnackbar({ severity: 'error', text: error.toString() });
            console.error(error);
        }

        setLoading(false);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        submit();
        return false;
    };

    return (
        <form onSubmit={onSubmit}>
            <UniversalSnack options={snackbar} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                sx={{ maxWidth: 400 }}
            >
                <Grid item container justifyContent="center">
                    <Grid item>
                        <PhonelinkLockIcon sx={{ fontSize: 64 }} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography textAlign="center">
                        Please enter your phone number so we can send SMS with
                        sign-in verification code.
                    </Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                    <CountryCodeSelect
                        country={countryType}
                        onChange={handleCountySelect}
                    />
                </Grid>
                <Grid item>
                    <PhoneNumberInput
                        prefix={countryType.phone}
                        onChange={setPhone}
                    />
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        loading={loading}
                    >
                        Save phone number
                    </LoadingButton>
                </Grid>
                <Grid item sx={{ mt: 1, opacity: 0.75 }}>
                    <LogOutButton />
                </Grid>
            </Grid>
        </form>
    );
}
