import { useNavigate } from 'react-router-dom';
import LoginResult from '../models/loginResult';
import useRedirectOfAccount from './useRedirectOfUser';

export default function useRedirectAfterSignIn() {
    const navigate = useNavigate();
    const redirect = useRedirectOfAccount();

    return (result: LoginResult) => {
        if (result.authy_need_to_enable) {
            navigate('/enable_authy', { replace: true });
            return;
        } else if (result.authy_required) {
            navigate('/confirm_authy', { replace: true });
            return;
        }

        redirect(result.user);
    };
}
