import React from 'react';

interface props {
    children: JSX.Element;
}

export default function Centered(props: props) {
    return (
        <div
            style={{
                padding: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            {props.children}
        </div>
    );
}
