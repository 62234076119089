import { useMemo } from 'react';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';

interface OnRoleProps {
    roles: string[];
    children?: any;
}

export default function OnRoles(props: OnRoleProps) {
    const currentAccountRoles = useCurrentUserRoles();
    const hasRoles = useMemo(() => {
        for (const role of props.roles) {
            if (currentAccountRoles.includes(role)) {
                return true;
            }
        }
        return false;
    }, [props.roles, currentAccountRoles]);

    return hasRoles ? props.children : null;
}
