import { useMemo, useState } from 'react';
import { GlobalSnackbarContext } from '../../contexts/GlobalSnackbarContext';
import SnackOptions from '../../models/snackOptions';
import UniversalSnack from './UniversalSnack';

export default function GlobalSnackbarSupport(props: { children: any }) {
    const [options, setOptions] = useState<SnackOptions | undefined>();

    return (
        <GlobalSnackbarContext.Provider
            value={{ value: options, setValue: setOptions }}
        >
            <>
                <UniversalSnack options={options} />
                {props.children}
            </>
        </GlobalSnackbarContext.Provider>
    );
}
