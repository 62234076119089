import { useLocation, useNavigate } from 'react-router-dom';
import UserDto from '../models/userDto';
import useFromUrl from './useFromUrl';

export default function useRedirectOfUser() {
    const navigate = useNavigate();
    const fromUrl = useFromUrl();

    return (user: UserDto) => {
        if (fromUrl) {
            window.location.href = fromUrl;
            return;
        }

        navigate('/barcodes', { replace: true });
    };
}
