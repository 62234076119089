import {
    Button,
    CircularProgress,
    Grid,
    Grow,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from '../../hooks/useCurrentUser';

export function NotFoundPage() {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    if (currentUser.isValidating || (!currentUser.data && !currentUser.error)) {
        return (
            <div style={{ padding: 16, height: '100%' }}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100%' }}
                >
                    <Grid item>
                        <Grow in timeout={{ enter: 2000 }}>
                            <CircularProgress />
                        </Grow>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <Grow in>
            <div style={{ padding: 16, height: '100%' }}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100%' }}
                >
                    <Grid item>
                        <Typography style={{ fontSize: 64 }}>😔</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">Page not found!</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            And this is sad. The error code is 404.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div style={{ height: 32 }} />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={() => navigate('/')}
                        >
                            Let's go home
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    );
}
