import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React from 'react';

interface props {
    open: boolean;
    onClose: () => any;
}

export default function LogOutAlert(props: props) {
    const { open, onClose } = props;

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Are you sure you want to logout?</DialogTitle>

            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button
                    href="/sign_out"
                    onClick={() => {
                        window.localStorage.removeItem('token');
                        onClose();
                    }}
                >
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );
}
