import { Button, Grid } from '@mui/material';
import HeaderSkeleton, { HeaderImplementationProps } from '../HeaderSkeleton';
import HeaderTitle from '../HeaderTitle';
import LogoutIcon from '@mui/icons-material/Logout';

export default function HeaderWithSignOut(props: HeaderImplementationProps) {
    return (
        <HeaderSkeleton {...props}>
            <Grid item container alignItems="center">
                <Grid item>
                    <HeaderTitle />
                </Grid>
                <Grid
                    item
                    sx={{ flex: 1 }}
                    container
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <Button
                            variant="text"
                            endIcon={<LogoutIcon />}
                            color="inherit"
                            href="/sign_out"
                            onClick={() =>
                                window.localStorage.removeItem('token')
                            }
                        >
                            Logout
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </HeaderSkeleton>
    );
}
