import { CircularProgress } from '@mui/material';
import Centered from './Centered';

export default function Fallback() {
    return (
        <Centered>
            <CircularProgress />
        </Centered>
    );
}
