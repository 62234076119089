import useSWR from 'swr';
import { rollbar } from '../constants/rollbar';
import { getAuthenticityToken } from '../lib/utils';
import UserDto from '../models/userDto';

export function useUser(userId: number | string | 'self') {
    return useSWR(`/api/v2/users/${userId}`, (...args) => get(userId));
}

export async function get(userId: number | string | 'self'): Promise<UserDto> {
    const response = await fetch(`/api/v2/users/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
    });

    if (response.status === 401) {
        const json = await response.json();

        throw new Error(json.message || 'You are not authorized');
    }

    if (response.status == 404) {
        throw new Error('Usser is not found');
    }

    if (!response.ok) {
        throw new Error('Response is not OK');
    }

    const user: UserDto = await response.json();

    rollbar.configure({
        payload: {
            person: {
                id: user.id,
                username: user.first_name + ' ' + user.last_name,
                email: user.email,
            },
        },
    });

    return user;
}
