import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    TextField,
    ThemeProvider,
    Typography,
    Alert,
    Divider,
} from '@mui/material';
import RequestTokenViaSMSButton from './RequestTokenViaSMSButton';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import LogOutButton from './LogOutButton';
import { LoadingButton } from '@mui/lab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SnackOptions from '../../models/snackOptions';
import UniversalSnack from '../commonComponents/UniversalSnack';
import API from '../../api';
import useRedirectOfAccount from '../../hooks/useRedirectOfUser';
import useCurrentUser from '../../hooks/useCurrentUser';

export default function VerifyAuthyTokenForm() {
    const [token, setToken] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [remember, setRemember] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackOptions | undefined>();

    const user = useCurrentUser();
    const redirect = useRedirectOfAccount();

    const handleChange = (event) => {
        setToken(event.target.value);
    };

    const handleRemember = (event) => {
        setRemember(event.target.checked);
    };

    const submit = async () => {
        setLoading(true);

        try {
            const result = await API.authy.confirm(token);

            await user.mutate(result.user);

            redirect(result.user);
        } catch (error) {
            setSnackbar({ severity: 'error', text: error.toString() });
            console.error(error);
        }

        setLoading(false);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        submit();
        return false;
    };

    return (
        <form onSubmit={onSubmit}>
            <UniversalSnack options={snackbar} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
                sx={{ maxWidth: 400 }}
            >
                <Grid item container justifyContent="center">
                    <Grid item>
                        <PhonelinkLockIcon sx={{ fontSize: 64 }} />
                    </Grid>
                </Grid>
                <Grid item sx={{ mt: 1 }}>
                    <Typography textAlign="center">
                        Please open Authy app on your phone where you can find
                        the code or request an SMS instead.
                    </Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                    <TextField
                        label="Code"
                        fullWidth
                        autoFocus
                        onChange={handleChange}
                        inputProps={{
                            autoComplete: 'one-time-code',
                        }}
                    />
                </Grid>
                {/* <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={remember}
                                onChange={handleRemember}
                            />
                        }
                        label="Remember Device"
                    />
                </Grid> */}

                <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={1}
                    sx={{ mt: 1 }}
                >
                    <Grid item sx={{ flex: 1 }}>
                        <RequestTokenViaSMSButton
                            onSnack={(o) => setSnackbar(o)}
                        />
                    </Grid>
                    <Grid item sx={{ flex: 1 }}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={loading}
                            endIcon={<NavigateNextIcon />}
                        >
                            Confirm
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Grid item sx={{ mt: 2, opacity: 0.75 }}>
                    <LogOutButton />
                </Grid>
            </Grid>
        </form>
    );
}
