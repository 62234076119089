import { AppBar, Grid, IconButton, styled } from '@mui/material';

import useBreakpointDown from '../../../../hooks/useBreakpointDown';
import BrandingLine from './BrandingLine';
import { Logo } from './Logo';
import MenuIcon from '@mui/icons-material/Menu';
import useSidebarAccess from '../../hooks/useSidebarAccess';
import { alpha } from '@mui/material/styles';
import useAppBarHeight from '../../../../hooks/useAppBarHeight';
import { useContext } from 'react';
import { EnvContext } from '../../../../contexts/EnvContext';
import useFooterHeight from '../../../../hooks/useFooterHeight';
import { BRANDING_LINE_HEIGHT } from '../Layout';

export interface HeaderSkeletonProps {
    onShowSidebarClicked: () => any;
    children?: any;
}

export interface HeaderImplementationProps
    extends Omit<HeaderSkeletonProps, 'children'> {}

export default function HeaderSkeleton(props: HeaderSkeletonProps) {
    const mediumDisplay = useBreakpointDown(1200);
    const mobile = useBreakpointDown(1050);
    const sidebarAccess = useSidebarAccess();
    const appBarHeight = useAppBarHeight();
    const appEnv = useContext(EnvContext);

    return (
        <AppBar
            position="fixed"
            elevation={0}
            square
            sx={{
                borderRadius: 0,
                zIndex: '999',
                height: appBarHeight,
                borderBottom: (theme) =>
                    `1px solid ${alpha(theme.palette.divider, 0.085)}`,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#191919' : 'white',
                boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.08)',
                color: (theme) =>
                    theme.palette.mode == 'dark'
                        ? 'rgba(255, 255, 255, 200)'
                        : 'rgba(0, 0, 0, 200)',
            }}
        >
            <Grid
                container
                direction="column"
                wrap="nowrap"
                sx={{ height: '100%' }}
            >
                {appEnv.brand == 'lm' && (
                    <Grid
                        item
                        sx={{
                            height: BRANDING_LINE_HEIGHT,
                        }}
                    >
                        <BrandingLine />
                    </Grid>
                )}
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="stretch"
                    wrap="nowrap"
                    sx={{
                        paddingLeft: mobile ? 2 : 4,
                        paddingRight: mobile ? 2 : 4,
                        height: '100%',
                        flex: 1,
                        // minHeight: 94,
                    }}
                    spacing={mediumDisplay ? 0 : 2}
                >
                    {sidebarAccess && (
                        <Grid item>
                            <Grid
                                container
                                justifyItems="center"
                                alignItems="center"
                                sx={{ height: '100%' }}
                            >
                                <Grid item>
                                    <IconButton
                                        aria-label="open sidebar"
                                        onClick={props.onShowSidebarClicked}
                                        edge="start"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {!mobile && (
                        <Grid item>
                            <Grid
                                container
                                justifyItems="center"
                                alignItems="center"
                                sx={{ height: '100%' }}
                            >
                                <Grid item sx={{ width: 34, height: 34 }}>
                                    <Logo />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {props.children}
                </Grid>
            </Grid>
        </AppBar>
    );
}
