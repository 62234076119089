import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useCallback, useState } from 'react';

export default function PasswordTextField(props?: TextFieldProps) {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = useCallback(() => {
        setShowPassword((prev: boolean) => !prev);
    }, []);

    return (
        <TextField
            label="Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                        >
                            {showPassword ? (
                                <VisibilityIcon />
                            ) : (
                                <VisibilityOffIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}
