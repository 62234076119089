import React from 'react';
import { countryToFlag } from '../../lib/utils';
import { useCountryTypes } from '../../hooks/useCountryTypes';
import { Autocomplete, Box, TextField } from '@mui/material';

export interface CountryCodeSelectProps {
    onChange: (phone: CountryType) => any;
    country: CountryType;
}

export default function CountryCodeSelect(props: CountryCodeSelectProps) {
    const { countries } = useCountryTypes();

    const handleChange = (_, phone) => props.onChange(phone);

    return (
        <Autocomplete
            disableClearable={true}
            value={props.country}
            options={countries}
            onChange={handleChange}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option: CountryType) => (
                <Box component="li" {...props} key={option.code}>
                    {option.label} ({option.code}) +{option.phone}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

export interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}
