import HeaderSkeleton, { HeaderImplementationProps } from './HeaderSkeleton';
import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import useBreakpointDown from '../../../../hooks/useBreakpointDown';
import HeaderTitle from './HeaderTitle';

export default function BlankHeader(props: HeaderImplementationProps) {
    const portrait = useBreakpointDown();

    const component = useMemo(() => {
        if (portrait) {
            return (
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ flex: 1 }}
                >
                    <Grid item>
                        <HeaderTitle />
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid item container alignItems="center">
                    <Grid item>
                        <HeaderTitle />
                    </Grid>
                </Grid>
            );
        }
    }, [portrait]);

    return <HeaderSkeleton {...props}>{component}</HeaderSkeleton>;
}
