import { useMemo } from 'react';
import useCurrentUser from './useCurrentUser';

export default function useCurrentUserRoles(): string[] {
    const user = useCurrentUser();

    return useMemo(() => {
        if (user.error) {
            return [];
        }

        if (!user.data) {
            return [];
        }

        return user.data.roles;
    }, [user.error, user.data]);
}
