import { useMemo } from 'react';

export interface AppEnvironment {
    value: 'staging' | 'production' | 'development';
    brand: 'lm' | 'mazevo';
}

const value: AppEnvironment = {
    value: 'production',
    brand: 'mazevo',
};

export default function useEnvironment(): AppEnvironment {
    return value;
}
