import { InputAdornment, TextField } from '@mui/material';

export interface PhoneNumberInputProps {
    onChange: (phone: string) => any;
    prefix: string;
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
    const handleChange = (event) => props.onChange(event.target.value);

    return (
        <TextField
            onChange={handleChange}
            label="Phone number"
            InputProps={{
                startAdornment: props.prefix ? (
                    <InputAdornment position="start">
                        +{props.prefix}
                    </InputAdornment>
                ) : null,
            }}
            style={{ width: '100%' }}
        />
    );
}
