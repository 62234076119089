import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import useRedirectOfUser from '../../../hooks/useRedirectOfUser';
import useCurrentUser from '../../../hooks/useCurrentUser';

export function AppIndexPage() {
    const user = useCurrentUser();
    const navigate = useNavigate();
    const redirect = useRedirectOfUser();

    useEffect(() => {
        if (user.error) {
            navigate('/sign_in');
        }

        if (user.data) {
            redirect(user.data);
        }
    }, [user.data, user.error]);

    return null;
}
