import useSWR from 'swr';
import { getAuthenticityToken } from '../lib/utils';
import BarcodesData from '../models/barcodesData';
import MazevoEater from '../models/mazevoEater';

export function useBarcodesData() {
    return useSWR(`/api/v2/barcodes/data`, (...args) => getBarcodesData());
}

export async function getBarcodesData(): Promise<BarcodesData> {
    const response = await fetch(`/api/v2/barcodes/data`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
    });

    if (!response.ok) {
        throw new Error('Response is not OK');
    }

    return await response.json();
}

export function useMazevoEater(pin: string | number) {
    return useSWR(
        `/api/v2/barcodes/eater_meals_for_today?scanned_barcode=${pin}`,
        (...args) => getMazevoEater(pin)
    );
}

export async function getMazevoEater(
    pin: string | number
): Promise<MazevoEater> {
    const response = await fetch(
        `/api/v2/barcodes/eater_meals_for_today?scanned_barcode=${pin}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': getAuthenticityToken(),
            },
        }
    );

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }

    return await response.json();
}

export interface MazevoSchoolEater {
    label: string;
    value: string;
}

export function useSchoolEaters() {
    return useSWR(`/api/v2/barcodes/school_eaters`, (...args) =>
        getSchoolEaters()
    );
}

export async function getSchoolEaters(): Promise<MazevoSchoolEater[]> {
    const response = await fetch(`/api/v2/barcodes/school_eaters`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
    });

    if (!response.ok) {
        throw new Error('Response is not OK');
    }

    return (await response.json()).eaters;
}

export async function acceptScan(
    scanId: string | number,
    orderItemsIds: number[]
): Promise<void> {
    const response = await fetch(`/api/v2/barcodes/accept_scan`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            scan_id: scanId,
            accepted_meals: orderItemsIds,
        }),
    });

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }
}

export async function addEmergencyMeal(options: {
    notes?: string;
    name: string;
    meal_type?: string;
    scan_id: string | number;
    menu_item_id?: string | number;
    daily_school_menu_id?: string | number;
    meal_size?: string;
    menu_type?: string;
}): Promise<void> {
    const response = await fetch(`/api/v2/barcodes/emergency_meal`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            scan_id: options.scan_id,
            meal_type: options.meal_type,
            meal: {
                notes: options.notes,
                name: options.name,
                menu_item_id: options.menu_item_id,
                daily_school_menu_id: options.daily_school_menu_id,
                meal_size: options.meal_size,
                menu_type: options.menu_type,
            },
        }),
    });

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }
}

export async function removeEmergencyMeal(
    meal_id: number | string,
    scan_id: number | string
): Promise<void> {
    const params = `scan_id=${scan_id}&meal_id=${meal_id}`;
    const url = `/api/v2/barcodes/emergency_meal?${params}`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
    });

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }
}

export async function addVolunteerLog(
    firstName: string,
    lastName: string,
    email: string
): Promise<void> {
    const response = await fetch(`/api/v2/barcodes/volunteer_log`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            email: email,
            volunteer_fn: firstName,
            volunteer_ln: lastName,
        }),
    });

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }
}

export async function addMessageOfSupport(
    notes: string,
    inventories: { note: string; id: number }[]
): Promise<void> {
    const response = await fetch(`/api/v2/barcodes/message_of_support`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': getAuthenticityToken(),
        },
        body: JSON.stringify({
            notes,
            inventories,
        }),
    });

    if (!response.ok) {
        const json = response.headers
            .get('Content-Type')
            ?.includes('application/json')
            ? await response.json()
            : {};

        throw new Error(json.message || response.statusText);
    }
}
