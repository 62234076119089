import { ListItemButton, ListItemText } from '@mui/material';
import { useMemo } from 'react';
import { CustomIcon } from '../SidebarDrawer';
import LogoutIcon from '@mui/icons-material/Logout';
import useCurrentUser from '../../../../hooks/useCurrentUser';

interface props {
    dense: boolean;
    onLogOutClicked: () => any;
}

export default function SidebarLogOut(props: props) {
    const currentUser = useCurrentUser();

    const button = useMemo(() => {
        return (
            <ListItemButton
                onClick={() => props.onLogOutClicked()}
                dense={props.dense}
            >
                <CustomIcon>
                    <LogoutIcon />
                </CustomIcon>
                <ListItemText
                    primary="Logout"
                    sx={{
                        '& p': {
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    }}
                    secondary={currentUser.data?.email}
                />
            </ListItemButton>
        );
    }, [currentUser.data, currentUser.error]);

    return button;
}
