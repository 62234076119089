import * as _ from 'lodash';
import MenuItemSize from '../enums/menuItemSize';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
              .toUpperCase()
              .replace(/./g, (char) =>
                  String.fromCodePoint(char.charCodeAt(0) + 127397)
              )
        : isoCode;
};

export const formatDate = (value: Date | string) =>
    new Date(value).toISOString().slice(0, 10);

export const isValidDate = (value: any) =>
    value instanceof Date && !isNaN(value.getTime());

export const getAuthenticityToken = () =>
    localStorage.getItem('token') || 'undefined';

export const sleep = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const camelToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function groupBy<T extends { [key: string]: any }>(
    getGroup: (entree: T) => string,
    array: T[]
) {
    return array.reduce((result: { [key: string]: T[] }, entree: T) => {
        const key = getGroup(entree);
        (result[key] = result[key] || []).push(entree);
        return result;
    }, {});
}

export function cloneDeep<T>(source: T): T {
    return _.cloneDeep(source);
}

export function removeElement(array: any[], element: any): void {
    const index = array.findIndex((i) => i === element);
    array.splice(index, 1);
}

export function dateWithoutTime(date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function ordinal_suffix_of(i: number): string {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st';
    }
    if (j == 2 && k != 12) {
        return i + 'nd';
    }
    if (j == 3 && k != 13) {
        return i + 'rd';
    }
    return i + 'th';
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function formatCurrency(value: number) {
    return formatter.format(value);
}

export const getFirstAvailableSize = (
    prices: Record<MenuItemSize, number | null>
) => {
    const entries = Object.entries(prices);
    const availableEntry = entries.find((entry) => entry[1] !== null);
    return availableEntry ? availableEntry[0] : undefined;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};
