import {
    Box,
    Button,
    Divider,
    Grid,
    Grow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import API from '../../api';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PasswordTextField from '../commonComponents/PasswordTextField';
import GoogleIcon from '@mui/icons-material/Google';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import useRedirectAfterSignIn from '../../hooks/useRedirectAfterSignIn';
import BorderedLogo from '../commonComponents/BorderedLogo';
import { EnvContext } from '../../contexts/EnvContext';
import useCurrentUser from '../../hooks/useCurrentUser';

const SignUpLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => (
        <RouterLink ref={ref} to="/sign_up" {...props} role={undefined} />
    )
);

const ResetPasswordLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => (
        <RouterLink
            ref={ref}
            to="/reset_password"
            {...props}
            role={undefined}
        />
    )
);

export default function SignInPage() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | undefined>();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const currentUser = useCurrentUser();
    const redirect = useRedirectAfterSignIn();
    const appEnv = useContext(EnvContext);

    const submit = async () => {
        if (email.trim().length === 0) {
            setEmailError('Please enter an email 🙄');
        } else {
            setEmailError(undefined);
        }

        if (password.length === 0) {
            setPasswordError('Please enter the password 🙄');
        } else {
            setPasswordError(undefined);
        }

        if (emailError || passwordError) return;

        setLoading(true);

        try {
            const result = await API.accounts.authorize({
                login: email,
                password,
            });

            currentUser.mutate(result.user);

            redirect(result);
        } catch (error) {
            setEmailError(error.message);
        }

        setLoading(false);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        submit();
        return false;
    };

    return (
        <Grow in>
            <form onSubmit={onSubmit} style={{ width: '100%', height: '100%' }}>
                <Grid
                    container
                    sx={{ p: { xs: 2, md: 3 }, width: '100%', height: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ maxWidth: 400 }}
                        spacing={1}
                    >
                        <Grid item>
                            <BorderedLogo borderSize={80} logoSize={48} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {appEnv.brand == 'lm'
                                    ? 'The LunchMaster'
                                    : 'Mazevo'}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ width: '100%', mt: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <EmailIcon sx={{ mr: 2 }} />
                                <TextField
                                    fullWidth
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(undefined);
                                    }}
                                    error={emailError !== undefined}
                                    helperText={emailError}
                                />
                            </Box>
                        </Grid>
                        <Grid item sx={{ width: '100%', mt: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <VpnKeyIcon sx={{ mr: 2 }} />
                                <PasswordTextField
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError(undefined);
                                    }}
                                    error={passwordError !== undefined}
                                    helperText={passwordError}
                                />
                            </Box>
                        </Grid>
                        {appEnv.brand == 'lm' && (
                            <Grid item container justifyContent="flex-end">
                                <Grid item sx={{ mr: -1, opacity: 0.5 }}>
                                    <Button
                                        component={ResetPasswordLink}
                                        variant="text"
                                        color="inherit"
                                    >
                                        <Typography variant="body2">
                                            Forgot your password?
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item sx={{ width: '100%', mt: 2 }}>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={loading}
                                fullWidth
                            >
                                Sign in
                            </LoadingButton>
                        </Grid>
                        {appEnv.brand == 'lm' && (
                            <>
                                <Grid item sx={{ width: '100%', mt: 2 }}>
                                    <Divider>Or</Divider>
                                </Grid>
                                <Grid item sx={{ width: '100%', mt: 2 }}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="inherit"
                                        href="/users/auth/google_oauth2"
                                        startIcon={<GoogleIcon />}
                                    >
                                        Sign in with Google
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ width: '100%', mt: 1 }}
                                >
                                    <Grid item>
                                        <Typography variant="body2">
                                            Don't have an account?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button component={SignUpLink}>
                                            Sign Up
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Grow>
    );
}
