import { Grid } from '@mui/material';
import EnableTwoFactorAuthenticationForm from './EnableTwoFactorAuthenticationForm';

export default function EnableTwoFactorPage() {
    return (
        <Grid
            container
            sx={{ p: { xs: 2, md: 3 }, width: '100%', height: '100%' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <EnableTwoFactorAuthenticationForm />
            </Grid>
        </Grid>
    );
}
