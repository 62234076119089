import { ListItemText, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { EnvContext } from '../../../../contexts/EnvContext';

interface props {}

export default function HeaderTitle(props: props) {
    const appEnv = useContext(EnvContext);

    return useMemo(() => {
        const title = appEnv.brand == 'lm' ? 'The LunchMaster' : 'Mazevo';
        return (
            <Typography variant="h6" fontSize={16}>
                {title}
            </Typography>
        );
    }, [appEnv]);
}
