import { Grow } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { EnvContext } from '../../../../contexts/EnvContext';
import { AppEnvironment } from '../useEnvironment';

interface props {
    size?: number;
}

export function Logo(props: props) {
    const logoSize = props.size || 34;
    const appEnv = useContext(EnvContext);

    return useMemo(
        () => (
            <div
                style={{
                    height: logoSize,
                    width: logoSize,
                    backgroundImage:
                        appEnv.brand == 'lm'
                            ? 'url("/assets/splash.png")'
                            : 'url("/assets/laurel-branch.png")',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    filter:
                        appEnv.brand == 'mazevo' ? 'contrast(0.8)' : undefined,
                }}
            />
        ),
        [logoSize, appEnv]
    );
}
