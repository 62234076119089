import useCurrentUserRoles from "../../../hooks/useCurrentUserRoles";
import {useMemo} from "react";

export default function useSidebarAccess() {
    const currentAccountRoles = useCurrentUserRoles();
    return useMemo(() => {
        return (
            currentAccountRoles.includes('superuser') ||
            currentAccountRoles.includes('lm_staff') ||
            currentAccountRoles.includes('lm_staff2')
        );
    }, [currentAccountRoles]);
}