import * as Authy from './authy';
import * as Users from './users';
import * as Barcodes from './barcodes';
import * as Accounts from './accounts';

export default class API {
    static readonly authy = Authy;
    static readonly users = Users;
    static readonly barcodes = Barcodes;
    static readonly accounts = Accounts;
}
