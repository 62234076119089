import { createTheme, PaletteOptions } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { deepmerge } from '@mui/utils';

export const mui5DarkPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
        main: '#FFCC00',
    },
    secondary: {
        main: '#FFCC00',
    },
};

export const mui5LightPalette: PaletteOptions = {
    primary: {
        main: '#16487F',
    },
    secondary: {
        main: '#16487F',
    },
};

export const defaultThemeOptions: ThemeOptions = {
    shadows: [
        'none',
        '1px 1px 20px rgba(0, 0, 0, 0.05)',
        '0px 1px 5px 0px rgb(0 0 0 / 20%)',
        '0px 1px 5px 0px rgb(0 0 0 / 25%)',
        '0px 1px 5px 0px rgb(0 0 0 / 30%)',
        '0px 1px 5px 0px rgb(0 0 0 / 35%)',
        '0px 1px 5px 0px rgb(0 0 0 / 45%)',
        '0px 1px 5px 0px rgb(0 0 0 / 50%)',
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
        '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
        '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
        '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
        '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
        '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
        '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
        '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
        '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
    components: {
        // MuiCssBaseline: {
        //     styleOverrides: {
        //         body: {
        //             scrollbarColor: 'transparent',
        //             scrollbarWidth: 'thin',
        //             '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        //                 backgroundColor: 'transparent',
        //             },
        //             '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        //                 borderRadius: 8,
        //                 backgroundColor: '#0000008c',
        //                 minHeight: 24,
        //                 border: '1px solid #00000063',
        //             },
        //             '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
        //                 backgroundColor: '#959595',
        //             },
        //             '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
        //                 backgroundColor: '#959595',
        //             },
        //             '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
        //                 backgroundColor: '#959595',
        //             },
        //             '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        //                 backgroundColor: 'transparent',
        //             },
        //         },
        //     },
        // },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    elevation: 3,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    borderRadius: 8,
                },
            },
            styleOverrides: {
                root: {
                    sx: {
                        borderRadius: 8,
                    },
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 1,
                sx: {
                    borderRadius: 2,
                },
            },
        },
    },
    typography: {
        button: {
            textTransform: 'none',
            fontWeight: 'normal',
        },
    },
};

const mui5Theme = createTheme(defaultThemeOptions);

export default mui5Theme;

const mui5ThemeOnRailsOptions: ThemeOptions = {
    typography: {
        // material-ui assumes that html font size is 14px
        // This line sets lib's base font size to the real one, so
        // it can calculate font sizes correctly
        htmlFontSize: 12,
    },
};

export const mui5ThemeOnRails = createTheme(
    deepmerge(defaultThemeOptions, mui5ThemeOnRailsOptions)
);
