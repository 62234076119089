import Rollbar from 'rollbar';

export const rollbarConfig: Rollbar.Configuration = {
    accessToken:
        process.env.NODE_ENV === 'production'
            ? '3a2876cc0af843a3a5fc14030f28a481'
            : 'ca1f886e9028409db0f3a5faa4b1ed90',
    captureUncaught: true,
    captureUnhandledRejections: true,
    scrubFields: ['credit_card[card_number]'],
    payload: {
        environment: process.env.NODE_ENV,
    },
};

export const rollbar = new Rollbar(rollbarConfig);
